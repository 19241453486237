<template>
  <v-overlay :value="show">
    <v-card
      v-click-outside="close"
      light
      class="pb-5"
      color="#333333"
      width="1300"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        <span>Копировать документ</span>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="teal"
        class="my-3"
      />
      <div class="white pa-5 rounded-0">
        <v-row>
          <v-col cols="6">
            <p>Скопировать блок:</p>
            <div class="fit-content">
              <v-checkbox
                v-for="(block,idx) in blocks"
                :key="`block-${idx}`"
                v-model="selectedBlocks"
                color="grey darken-3"
                :value="block.value"
                :label="block.name"
              />
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
            <div class="d-flex align-center">
              <v-checkbox
                v-model="withWares"
                color="grey darken-3"
                :label="`Товары (${selectedWares.length}/${items.length})`"
                hide-details="true"
              />
            </div>
          </v-col>
          <v-col cols="2">
            <v-btn
              class="elevation-0 px-15"
              color="#5CB7B1"
              dark
              @click="toggleWares"
            >
              <span v-show="!showWares">
                Показать товары <v-icon>mdi-chevron-down</v-icon>
              </span>
              <span v-show="showWares">
                Скрыть товары <v-icon>mdi-chevron-up</v-icon>
              </span>
            </v-btn>
          </v-col>
        </v-row>
        <v-slide-y-transition>
          <div v-show="showWares">
            <div class="d-flex my-4">
              <v-text-field
                v-model="range"
                hide-details="auto"
                background-color="#EDEDED"
                class="ba-0 rounded-l-sm rounded-r-0"
                filled
                rounded
                placeholder="Введите диапазон"
                @keyup.enter="getIdsFromRange"
              />
              <v-btn
                height="auto"
                class="elevation-0 my-0 rounded-l-0"
                x-large
                dark
                min-width="50px"
                @click="getIdsFromRange"
              >
                <v-icon>mdi-plus</v-icon>
                <span class="d-none d-lg-block">Выбрать</span>
              </v-btn>
            </div>
            <div
              class="elements__table"
            >
              <v-virtual-scroll
                :items="items"
                :bench="10"
                :item-height="50"
                :height="height"
              >
                <template #default="{index,item}">
                  <copy-document-element
                    :item="item"
                    :index="index"
                    :selected="selectedWares.includes(item.id)"
                    @select="onItemSelect"
                  />
                </template>
              </v-virtual-scroll>
            </div>
          </div>
        </v-slide-y-transition>

        <div class="d-flex justify-end mt-4">
          <v-btn
            elevation="0"
            color="#EDEDED"
            class="mr-2"
            @click="unset({cancel:true})"
          >
            Отмена
          </v-btn>
          <v-btn
            color="#5CB7B1"
            elevation="0"
            dark
            @click="submit"
          >
            Копировать
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import {isGoodsRangeValid, rangeToArray , isCorrectStartEnd, trimRange} from '@/helpers/inputs'
import closeOnEscape from '@/mixins/close-on-escape.mixin.js'
import CopyDocumentElement from '@/components/documents/copy-document-element.vue'
import moment from "moment"

export default {
  components:{
    CopyDocumentElement,
  },
  mixins:[closeOnEscape],
  props:{
    show:{
      required:true,
      type:Boolean
    },
    id:{
      type:Number,
      required:false,
      default: -1
    }
  },
  data:() => ({
    loading:false,
    blocks:[
      {
        name:"Документы",
        value:"presented_documents"
      },
      {
        name:"Гарантии",
        value:"warranty"
      }
    ],
    withWares:true,
    showWares:false,
    selectedBlocks:[],
    items:[],
    selectedWares:[],
    range:"",
    validRange:""
  }),
  computed:{
    height(){
      return window.innerHeight - 500
    }
  },
  watch:{
    range(nv) {
      this.validateRange(nv.replace(".", ","));
    },
    withWares(nv){
      if(nv === true){
        this.selectedWares = this.items.map(i => i.id)
      }else{
        this.selectedWares = []
      }
    },
    show:{
      handler(nv){
        if(nv === true){
          // Гарантии по умолчанию false
          this.selectedBlocks = this.blocks.filter(i => !["warranty"].includes(i.value)).map(i => i.value)
          this.loadWares().then(wares => {
            this.items = wares.map((item, idx) => {
              const {
                id,
                tn_ved,
                add_tn_ved,
                net_weight,
                gross_weight,
                invoice_cost,
                cargo_place_quantity,
                package_type_code,
                shipment_date: date,
                invoice_currency_letter,
                origin_country_letter,
                title: titleDefault = "",
              } = item
              const shipment_date = date ? moment(date).format('DD.MM.YYYY') : ''
              const title = titleDefault !== null && titleDefault.length > 80 ? titleDefault.slice(0, 80) + '...' : titleDefault
              const cost = invoice_cost && invoice_currency_letter ? `${invoice_cost} ${invoice_currency_letter}` : invoice_cost

              return {
                id,
                index:idx + 1,
                tn_ved,
                add_tn_ved,
                title,
                shipment_date,
                net_weight,
                gross_weight: `${gross_weight} КГ`,
                cargo_place_quantity,
                package_type_code,
                invoice_cost,
                cost,
                invoice_currency_letter,
                origin_country_letter,
              }
            })
            this.onSelectAll()
          })


        }
      },
      immediate:true
    },
  },

  beforeDestroy(){
    this.unset()
  },
  methods:{
    loadWares(){
      if(this.id > 0){
        return this.$store.dispatch('epi/getDocumentInfo', this.id).then(res => {
          const [shipment] = res.data?.ware_shipments ?? []
          return shipment?.wares ?? []
        })
      }else{
        const [,document] = this.$route.path.split('/')
        const selected = this.$store.getters[`${document}/getSelected`]
        const [shipment] = selected.ware_shipments ?? []
        const items = shipment?.wares ?? []
        return Promise.resolve(items)
      }
    },
    submit(){
      this.loading = true
      const [,,id] = this.$route.path.split('/')
      this.$store.dispatch('epi/copyDocument',{
        id: this.id > 0 ? this.id : id, // this.id передается через пропс напр. в журнале
        copyDocuments:this.selectedBlocks.includes("presented_documents"),
        copyGuarantees:this.selectedBlocks.includes("warranty"),
        wareIds:this.selectedWares,
        copyAllWares:this.selectedWares.length === this.items.length,
        // options
      }).then((res) => {
        this.loading = false
        const id = res.data
        this.$snackbar({text:`Документ скопирован. Новый документ: ${id}`, color:"green", top:false, right:false, timeout:10000})
        // Обновление журнала через событие сокета !
        this.unset()
        this.close()
      }).catch(err => {
        this.loading = false
        this.$snackbar({text: err.response || "Ошибка",color:"red", top:false, right: false});
      })
    },
    validateRange(nv) {
      const result = isGoodsRangeValid(nv);
      if (result !== false) {
        this.validRange = result;
        this.range = result;
      } else {
        this.$nextTick(() => {
          this.range = this.validRange
        });
      }
    },
    getIdsFromRange(){
      this.range = trimRange(this.range)
      if(!isCorrectStartEnd(this.range)){
        return this.$snackbar({text:"Неверный диапазон", color:'red',top:false, right: false})
      }
      const idxs = rangeToArray(this.range, true)
      this.selectedWares = idxs.map(idx => {
        return this.items[idx]?.id
      })
    },
    unset({cancel = false} = {}){
      this.withWares = true
      this.showWares = false
      this.selectedBlocks = this.blocks.filter(i => ['presented_documents'].includes(i.value)).map(i => i.value)
      this.loading = false
      if(cancel){
        this.onSelectAll()
      }else{
        this.selectedWares = []
        this.items = []
      }
    },
    toggleWares(){
      this.showWares = !this.showWares
    },
    onSelectAll(){
      this.withWares ? this.selectedWares = this.items.map(i => i.id) : this.selectedWares = []
    },
    onItemSelect(id){
      if(this.selectedWares.includes(id)){
        this.selectedWares = this.selectedWares.filter(i => i !== id)
      }else{
        this.selectedWares.push(id)
      }
    },
    close(){
      this.unset()
      this.$emit('update:show', false)
    }
  }
}
</script>
<style scoped>
.fit-content{
    width:fit-content
}
</style>
